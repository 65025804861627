<template>
  <b-card
    class="m-2 borderless"
    body-class="p-2"
    borderless
  >
    <b-card-title>
      {{ helpTextTitle }}
    </b-card-title>
    <table>
      <tr
        v-for="(row, index) in helpTextRows"
        :key="index"
      >
        <td class="icon-column">
          <i
            v-for="icon in row.icons"
            :key="icon"
            class="icon-size pl-2"
            :class="icon"
            :style="{color: row.iconColor}"
          ></i>
        </td>
        <td class="description-column">
          {{ row.description }}
        </td>
        <td class="text-column">
          {{ row.text }}
        </td>
      </tr>
    </table>
  </b-card>
</template>

<script>

export default {
  name: 'IconHelpTable',
  props: {
    helpTextTitle: {
      default: ''
    },
    helpTextRows: {
      default: () => {
        return {
        }
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
borderless {
  border: none
}
$icon-font-size: 26px;
.icon-size {
  font-size: $icon-font-size;
}
tr {
  border-bottom: 1px solid #EEE;
}
td.icon-column {
  text-align: center;
   width: 10%;
}
td.description-column {
  width: 20%;
  text-align: left;
}
td.text-column {
  width: 70%;
  text-align: left;
  padding: 0;
}
.pointer {
  cursor: pointer;
}
.no-outline:focus {
  outline: none;
  background-color: #EEE;
}
</style>
