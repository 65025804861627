<template>
  <div
     class="d-flex p-2"
  >
  <b-list-group
  >
    <b-list-group-item>
      <b-button :pressed.sync="toggleFeedback" variant="primary">{{ messages.getActionFeedback() }}</b-button>
      <div class="iframe-container" v-if="toggleFeedback">
        <iframe
          :title="messages.getLabelFeedbackForm()"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          class="feedback-form"
          :src="messages.getLabelFeedbackFormAddress()"
        >
          {{ messages.getLabelFeedbackFormIframesDisabled() }}
        </iframe>
      </div>
    </b-list-group-item>
    <b-list-group-item>
      <icon-help-table
        :helpTextRows="confirmationIconsHelpTexts"
        :helpTextTitle="confirmationIconsHelpTitle"
      />
    </b-list-group-item>
    <b-list-group-item>
      <icon-help-table
        :helpTextRows="deliveryIconsHelpTexts"
        :helpTextTitle="deliveryIconsHelpTitle"
      />
    </b-list-group-item>
    <b-list-group-item>
      <icon-help-table
        :helpTextRows="locationIconsHelpTexts"
        :helpTextTitle="locationIconsHelpTitle"
      />
    </b-list-group-item>
    <b-list-group-item>
      <h1>
        {{ messages.getLabelSearch() }}
      </h1>
      <p>
        {{ messages.getLabelSearchInstructionSummary() }}
      </p>
      <p>
        {{ messages.getLabelSearchInstructionDetails() }}
      </p>
    </b-list-group-item>
    <b-list-group-item v-if="instructionsLinkUrl && instructionsLinkText">
      <h1>
        {{ messages.getLabelInstructions() }}
      </h1>
      <p>
        <b-link
          :href="instructionsLinkUrl"
          target="_blank"
        >
          {{ instructionsLinkText }}
        </b-link>
      </p>
    </b-list-group-item>
    <b-list-group-item>
      <changelog />
    </b-list-group-item>
  </b-list-group>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'
import Changelog from '../../CHANGELOG.md'
import IconHelpTable from '../components/IconHelpTable'
import colorVariables from '@/assets/css/custom.scss'

export default {
  name: 'HelpPage',
  components: { Changelog, IconHelpTable },
  data () {
    return {
      toggleFeedback: false,
      confirmationIconsHelpTitle: messages.getLabelIconListTitleForConfirmation(),
      confirmationIconsHelpTexts: [
        {
          icons: [''],
          iconColor: null,
          description: messages.getLabelMissingIcon(),
          text: messages.getLabelMissingIconForConfirmation()
        },
        {
          icons: ['icon-assignment'],
          iconColor: colorVariables.yellow,
          description: messages.getLabelYellowIcon(),
          text: messages.getLabelYellowIconForConfirmation()
        },
        {
          icons: ['icon-assignment'],
          iconColor: colorVariables.green,
          description: messages.getLabelGreenIcon(),
          text: messages.getLabelGreenIconForConfirmation()
        },
        {
          icons: ['icon-assignment'],
          iconColor: colorVariables.gray,
          description: messages.getLabelGreyIcon(),
          text: messages.getLabelGreyIconForConfirmation()
        }
      ],

      deliveryIconsHelpTitle: messages.getLabelIconListTitleForDelivery(),
      deliveryIconsHelpTexts: [
        {
          icons: [''],
          iconColor: null,
          description: messages.getLabelMissingIcon(),
          text: messages.getLabelMissingIconForDelivery()
        },
        {
          icons: ['icon-truck-1'],
          iconColor: colorVariables.yellow,
          description: messages.getLabelYellowIcon(),
          text: messages.getLabelYellowIconForDelivery()
        },
        {
          icons: ['icon-truck-1'],
          iconColor: colorVariables.green,
          description: messages.getLabelGreenIcon(),
          text: messages.getLabelGreenIconForDelivery()
        },
        {
          icons: ['icon-truck-1'],
          iconColor: colorVariables.gray,
          description: messages.getLabelGreyIcon(),
          text: messages.getLabelGreyiconForDelivery()
        },
        {
          icons: ['icon-truck-1'],
          iconColor: colorVariables.red,
          description: messages.getLabelRedIcon(),
          text: messages.getLabelRediconForDelivery()
        }
      ],

      locationIconsHelpTitle: messages.getLabelIconListTitleForLocation(),
      locationIconsHelpTexts: [
        {
          icons: [''],
          iconColor: null,
          description: messages.getLabelMissingIcon(),
          text: messages.getLabelMissingIconForLocation()
        },
        {
          icons: ['icon-warehouse', 'icon-location'],
          iconColor: colorVariables.gray,
          description: messages.getLabelGreenHousePointIcons(),
          text: messages.getLabelGreyHousePointIconsForLocation()
        },
        {
          icons: ['icon-warehouse', 'icon-location'],
          iconColor: colorVariables.green,
          description: messages.getLabelGreenHousePointIcons(),
          text: messages.getLabelGreenHousePointIconsForLocation()
        }
      ],
      messages
    }
  },
  computed: {
    instructionsLinkUrl () {
      return this.$store.getters.instructionsLinkUrl
    },
    instructionsLinkText () {
      return this.$store.getters.instructionsLinkText
    }
  },

  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
.feedback-form {
  margin-top: 8px;
  position: absolute;
  border-width: 0;
  float: left;
  border: 0;
  width: 100%;
  height: 100%;
}
.iframe-container {
  top: 0px;
  left: 0px;
  position: relative;
  padding-bottom: 50%;
  height: 0;
  max-width: 900px;
  min-height: 900px;
  overflow: hidden;
}
@media (max-width: 550px) {
  .iframe-container {
    min-height: 1100px;
  }
}
@media (max-width: 700px) {
  .iframe-container {
    min-height: 1000px;
  }
}
@media (max-width: 900px) {
  .iframe-container {
    min-height: 950px;
  }
}
</style>
